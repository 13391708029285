/* eslint-disable @typescript-eslint/no-empty-function */
//// ie 11 pollyfills
/** @jsxRuntime classic */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
// WebOS 3.0 needs this to be declared up front so usages of globalThis don't blow up
import '@ungap/global-this';
// IE11 needs "jsxRuntime classic" for this initial file which means that "React" needs to be in scope
// issue: https://github.com/facebook/create-react-app/issues/9906
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect } from 'react';

checkMobxBabelConfiguration();

import { createRoot } from 'react-dom/client';
import './styles/index.css';
import { Context } from 'context/context';
import Root from 'pages/Shell/Shell';
import { APP_VERSION } from 'version';
import { Device, getDevice } from 'utils/getDevice';
import { DomainEnv, getDomainEnv, shouldUseMockServiceWorker } from 'utils/assets';
import WebosGlobalStyle from 'utils/webosGlobalStyle';
import { logger } from 'logger';
import { initKochava } from 'utils/kochava';
import { init as initSpacialNav } from '@noriginmedia/norigin-spatial-navigation';
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import smoothscroll from 'smoothscroll-polyfill';
import { useGamepadNavigation } from 'hooks/useGamepadNavigation';
import { createHashRouter, createRoutesFromElements, RouterProvider, Route, Navigate } from 'react-router-dom';
import consolere from 'console-remote-client';
import { remoteConsoleStore } from 'apolloGraphql/mml-apollo-cache';
import { loadableRoutes, redirects } from 'routes/routes';
import { AppWorkerFactoryInstance } from './web-workers/AppWorkerFactory';

const device = getDevice();
const AppWorkerProxy = AppWorkerFactoryInstance.create(device);

// This allows us to remotely view the console.
// You can navigate to https://console.re/ncaa-mml-pwa-${device}-${domainEnv} to view the console messages remotely
// For example, if you are testing xbox pointed at qa, the url would be https://console.re/ncaa-mml-pwa-xbox-qa
if (getDomainEnv() !== DomainEnv.PROD && getDomainEnv() !== DomainEnv.STAGING && remoteConsoleStore()) {
  consolere.connect({
    channel: `ncaa-mml-pwa-${device}-${getDomainEnv()}`,
    redirectDefaultConsoleToRemote: true,
    disableDefaultConsoleOutput: false,
  });
}

const DOM_NODE = document.getElementById('root') as HTMLElement;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LOG = logger.getLogger('index.tsx');
const root = createRoot(DOM_NODE);
const theme = createTheme({
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff1c',
        },
      },
    },
  },
});

// Enable polyfill for webOS <=4 and Xbox/Edge 18
smoothscroll.polyfill();

initKochava();
initSpacialNav({ debug: false, visualDebug: false });

function checkMobxBabelConfiguration() {
  if (
    !new (class {
      x: unknown;
    })().hasOwnProperty('x')
  ) {
    throw new Error('Transpiler is not configured correctly');
  }
}
interface Props {
  Component: typeof Root;
}

declare global {
  interface Window {
    mandatory: string | undefined;
    version?: string;
  }
}

const ConnectedApp = ({ Component }: Props): JSX.Element => {
  const device = getDevice();
  useGamepadNavigation();

  const router = createHashRouter(
    createRoutesFromElements(
      <Route element={<Component />}>
        {/* The routes can't be loaded from a different component and must be direct children */}
        {redirects.map(({ from, to }) => (
          <Route key={from} element={<Navigate to={to} replace />} />
        ))}
        {/* Map over the configured routes */}
        {Object.entries(loadableRoutes).map(([path, { Component, props }]) => (
          <Route key={path} {...props} path={path} element={<Component />} />
        ))}
        <Route element={<Navigate to="/" replace />} />
      </Route>,
    ),
    { basename: '' },
  );

  useEffect(() => {
    if (shouldUseMockServiceWorker()) {
      const startMockServiceWorker = async () => {
        const { worker } = await import(/* webpackChunkName: "MockBrowserWorker" */ './__mocks__/browser');
        return await worker.start();
      };

      startMockServiceWorker()
        .then(() => console.log('Mock service worker started'))
        .catch((reason) => console.error(`Error starting mock service worker:  ${reason}`));
    }
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Context>
          <RouterProvider router={router} />
          {device === Device.lgtv && <WebosGlobalStyle />}
        </Context>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

const render = (Component: typeof Root): void => {
  console.log(`March Madness Live v${APP_VERSION.version}`);
  self.version = APP_VERSION.version;
  root.render(<ConnectedApp Component={Component} />);
};

async function startMockServiceWorker() {
  if (shouldUseMockServiceWorker()) {
    try {
      const { worker } = await import(/* webpackChunkName: "MockBrowserWorker" */ './__mocks__/browser');
      await worker.start();
      console.log('Mock service worker started');
    } catch (reason) {
      console.error(`Error starting mock service worker:  ${reason}`);
    }
  }
}
async function prepare() {
  return Promise.all([AppWorkerProxy.analyticsLoad(process.env.SEGMENT_KEY), startMockServiceWorker()]);
}

prepare().then(() => render(Root));
analytics.load(process.env.SEGMENT_KEY ?? 'test');
